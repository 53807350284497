// Importing necessary styles, libraries, and modules for UI interactions and data fetching.
import "../styles/index.scss";
import $ from "jquery";
import { getJson } from "./getJson";
import { MenuItem } from "./MenuItemsClass";
import initBuilderTools from "./helpers";
import {
    addListenersOnButtons, calcPrice,
    hideBackgroundButton,
    scrollMenuToTop, sendCheckboxesToServer,
    setListenersOnRebuild, showHideInputs,
    validateInputs
} from "./UIfunctions";
import initShowBackgroundListeners from "./backgroundsFunctions";
import { IMAGES_VERSION_CACHE_REFRESH } from "./consts";
import { constructSelectionsOnload } from "./selections";
import "animate.css"; // Importing CSS animations library.

// Parsing global JSON data embedded in the HTML to initialize constants for the session.
export const sessionPrefix = JSON.parse(document.getElementById("sessionPrefix").textContent),
    layoutType = JSON.parse(document.getElementById("layoutType").textContent),
    layoutName = JSON.parse(document.getElementById("layoutName").textContent),
    allPropertyValues = JSON.parse(document.getElementById("allPropertyValues").textContent),
    isUserLoggedIn = JSON.parse(document.getElementById("isUserLoggedIn").textContent),
    orderId = JSON.parse(document.getElementById("orderId").textContent);

// Once the document is fully loaded, execute the following asynchronous function.
$(document).ready(async function (e) {
    // Perform the initial JSON fetch to build the entire menu.
    let firstFetch = await getJson({
            e,
            category: "all",
            id: 0,
            buildFromSession: false,
            layoutType,
            layoutName
        }),
        layoutState = firstFetch.layoutState; // Store the fetched layout state.
    global.menuData = firstFetch.menuData; // Store the fetched menu data globally.
    global.menuStateNames = firstFetch.menuStateWithPropNames; // Store the menu state names globally.

    // Apply the fetched layout state to the UI, setting background images for div elements.
    for (let [divName, divObj] of Object.entries(layoutState)) {
        $(`.image-display[data-div-name="${divName}"]`).css({
            "background-image": `url(${divObj.imageUrl}?${IMAGES_VERSION_CACHE_REFRESH})`,
        });
    }

    // Loop through the fetched menu data and build menu items for the UI.
    await global.menuData.forEach((el) => {
        let menuItem = new MenuItem(
            el.id,
            el.active,
            el.showInFrontend?.name ? el.showInFrontend.name : el.name,
            el.category,
            el.structure,
            el.thumbUrl,
            el.images,
            el.subCategoryItems,
            el.showInFrontend,
            el.dontShowInFrontend,
            el.parent
        );
        menuItem.buildMenuItem(); // Construct the menu item and append it to the menu.
    });

    // Initialize UI builder tools and various UI functionalities.
    initBuilderTools(); // Initializes tools for the UI builder.
    hideBackgroundButton(); // Hides the background button if necessary.
    addListenersOnButtons(); // Adds event listeners to buttons.
    setListenersOnRebuild(); // Resets listeners after rebuilding the UI.
    initShowBackgroundListeners(); // Initializes listeners related to background elements.
    scrollMenuToTop(); // Scrolls the menu to the top upon initial load.
    constructSelectionsOnload(); // Constructs selections from the saved session if available.
    validateInputs(); // Validates inputs on the UI for the dimensions.
    showHideInputs(); // Shows or hides inputs based on the selected structure.
    await calcPrice(); // Calculates the price of the current selection
    sendCheckboxesToServer(); // Sends the checkboxes to the server to be saved in the session.
});
