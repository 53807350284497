import $ from "jquery";
import Swal from "sweetalert2";
import { sessionPrefix } from "./index";
import { itemActive } from "./itemActiveClosure";

// Function to initialize interactive builder tools in the UI.
export default function initBuilderTools() {
    // jQuery object for the builder panel.
    let builderPanel = $('#builder-panel');

    // Event handler for the 'show build tools' button.
    $('#show-build-tools').on('click', function () {
        // Toggle visibility of the builder panel and update the button text accordingly.
        let isVisible = showHideElementAndToggleClass(builderPanel, 'visible');
        // Set the button text to show/hide based on the builder panel's visibility.
        isVisible ? $(this).html($(this).attr('data-hide-menu-text')) : $(this).html($(this).attr('data-show-menu-text'));
    });

    // Event handler for toggling visibility of divs in the builder panel.
    $('#builder-panel .toggle').on('click', function () {
        // Get the name of the div that corresponds to the toggle button clicked.
        let divName = $(this).closest('li').find('.div-name').attr('data-div-name');
        // Show or hide the div based on the toggle button's state.
        $(this).is(':checked') ? $('#' + divName).show() : $('#' + divName).hide();
    });

    // Event handler for the 'update current db layout' button.
    $('#update-current-db-layout').on('click', function () {
        // Send a POST request to save the current layout to the database.
        $.post('/core/ucdl/', {
            'sessionPrefix': sessionPrefix,
            'csrfmiddlewaretoken': $("[name=csrfmiddlewaretoken]").val(),
        }, function () {
            // On success, display a toast message indicating the save was successful.
            fireSwalToast(2000, 'Saved Successfully', 'success', 'bottom-end', false);
        }).fail(function () {
            // On failure, alert the user that the request failed.
            alert('Request failed!');
        });
    });

    // Event handler for the 'show price debug info' button.
    $('#show-price-debug-info').on('click', function () {
        // Toggle visibility of the price debug information and update the button's class.
        if ($(this).hasClass('show')) {
            $('#price-debug-info').hide();
            $(this).removeClass('show');
        } else {
            $('#price-debug-info').show();
            $(this).addClass('show');
        }
    });
}

// Function to show or hide an element and toggle a class on it.
function showHideElementAndToggleClass(element, className) {
    let isVisible = false;
    if (element.hasClass(className)) {
        // If the element has the class, hide it and remove the class.
        element.hide();
        element.removeClass(className);
    } else {
        // If the element does not have the class, show it and add the class.
        element.show();
        element.addClass(className);
        isVisible = true;
    }
    return isVisible;
}

// Function to display a toast message using SweetAlert2.
export function fireSwalToast(timeMs, titleText, iconType, pos, progressBar) {
    // Create a SweetAlert2 mixin for toasts.
    const Toast = Swal.mixin({
        toast: true,
        position: pos,
        showConfirmButton: false,
        timer: timeMs,
        timerProgressBar: progressBar,
        onOpen: (toast) => {
            // Pause the timer when the mouse is over the toast and resume when it leaves.
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });
    // Fire the toast with the specified icon and title.
    Toast.fire({
        icon: iconType,
        title: titleText
    });
}

// Function to delay execution of callbacks, typically used for debouncing event listeners.
export function delayForListeners(callback, ms) {
    let timer = 0;
    return function () {
        let context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            // Apply the callback function with the given context and arguments after a delay.
            callback.apply(context, args);
        }, ms || 0);
    };
}

// Function to save the current selection to the session and optionally send statistics.
async function saveCurrentSelectionToSession(sendStatistic = false, statisticAction = null) {
    // Retrieve current session prefix and dimension values from the DOM.
    let sessionPrefix = JSON.parse(document.getElementById('sessionPrefix').textContent);
    let dimensions = {
        width: document.getElementById('input-width').value,
        length: document.getElementById('input-length').value
    };
    // Construct the request options for saving the current layout.
    const url = "/save_current_layout/",
        myInit = {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": $("input[name=csrfmiddlewaretoken]").val(),
            },
            mode: "cors",
            cache: "default",
            body: JSON.stringify({
                session_prefix: sessionPrefix,
                activeDimensions: JSON.stringify(dimensions),
                activeItems: JSON.stringify(itemActive.showActiveCode())
            })
        };
    // Perform the POST request to save the layout.
    let results = await fetch(url, myInit);
    let response = await results.json();

    // if (sendStatistic && statisticAction) {
    //     await sendStatisticToServer(statisticAction);
    // }

    return response;
}

// async function sendStatisticToServer(statisticAction) {
//     // console.log('send');
//     // Make POST request to update the statistics and excel file.
//     const urlForExcel = `/save-to-excel/`,
//         myInit = {
//             method: "POST",
//             credentials: "same-origin",
//             headers: {
//                 "Content-Type": "application/json",
//                 "X-CSRFToken": $("input[name=csrfmiddlewaretoken]").val(),
//             },
//             mode: "cors",
//             cache: "default",
//             body: JSON.stringify({
//                 session_prefix: sessionPrefix,
//                 typeOfAction: statisticAction
//             })
//         };
//
//     try {
//         let newres = await fetch(urlForExcel, myInit),
//             response = await newres.json(),
//             {success} = response;
//         if (success)
//             console.log(
//                 success,
//                 "the data has been successfully saved in the excel"
//             );
//         else
//             console.log(
//                 success,
//                 "the data has not been successfully saved in the excel"
//             );
//     } catch (e) {
//         console.log(e);
//     }
// }

// Export the saveCurrentSelectionToSession and sendStatisticToServer functions for use elsewhere.
export {
    saveCurrentSelectionToSession,
    // sendStatisticToServer
};
