import $ from "jquery";
import { toggleSelections } from "./selections";

// Function to toggle the visibility of the backgrounds container.
export function showBackgrounds() {
    // Select the background container element using its ID.
    const backgroundContainer = document.querySelector("#backgrounds-container");
    // Use jQuery to select the selections container element.
    const selectionsContainer = $("#selections-container");

    // If the selections container is not hidden, toggle its visibility.
    if (!selectionsContainer.hasClass('hidden')) {
        toggleSelections(); // This calls the function imported from "./selections".
    }

    // Check if the background container is hidden and toggle its visibility accordingly.
    if (backgroundContainer.classList.contains("hidden")) {
        backgroundContainer.classList.toggle("hidden"); // Show the container if it is hidden.
    } else {
        // If it's visible, set a timeout to hide the container after 800 milliseconds.
        setTimeout(function () {
            backgroundContainer.classList.toggle("hidden"); // Hide the container.
        }, 800);
    }

    // Toggle animation classes to add a fade effect when showing or hiding the background container.
    backgroundContainer.classList.toggle("animate__fadeIn");
    backgroundContainer.classList.toggle("animate__fadeOut");

    // Toggle a class on the backgrounds on/off switch to indicate its state visually with a red filter.
    $('#backgrounds-on-off').toggleClass('red-filter');
}

// The default export is an anonymous function that adds a click event listener to the backgrounds on/off switch.
export default function () {
    // When the backgrounds on/off switch is clicked, call the showBackgrounds function.
    document.querySelector("#backgrounds-on-off").addEventListener("click", showBackgrounds);
}
