// Importing necessary libraries and components for UI manipulation and AJAX requests.
import $ from "jquery";
import replaceImages from "./imagesTools";
import {getJson} from "./getJson";
import {MenuItem} from "./MenuItemsClass";
import {showBackgrounds} from "./backgroundsFunctions";
import {saveCurrentSelectionToSession} from "./helpers";
import {itemActive} from "./itemActiveClosure";

// Set up a jQuery selector for the image element that triggers selection actions.
const sele = $('#selections-img');
// Predefined array of letters used as identifiers or categories in the application.
const LETTERS = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n'];

// Attach an event handler to the selection image for click events to trigger opening/closing of selections.
sele.on('click', openCloseSelections);

// Function to toggle the display of the background container and selections.
export function openCloseSelections() {
    const backgroundContainer = document.querySelector("#backgrounds-container");
    // Hide the backgrounds if they are currently visible.
    if (!backgroundContainer.classList.contains("hidden")) {
        showBackgrounds();
    }
    // Call a function to toggle the selection visibility.
    toggleSelections();
}

// jQuery selector for the container that will display or hide selections.
const selectionsContainer = $("#selections-container");

// Function to control the visibility and animation of the selections container.
export function toggleSelections() {
    // Check if the selections container is currently hidden.
    if (selectionsContainer.hasClass("hidden")) {
        // If it is hidden, remove the 'hidden' class to show it.
        selectionsContainer.toggleClass("hidden");
    } else {
        // If it is visible, set a timeout to hide the container after 800 milliseconds.
        setTimeout(function () {
            selectionsContainer.toggleClass("hidden");
        }, 800);
    }
    // Toggle animation classes to add a fade in or fade out effect.
    selectionsContainer.toggleClass("animate__fadeIn");
    selectionsContainer.toggleClass("animate__fadeOut");
    // Toggle a 'red-filter' class on the image within the selections container to indicate selection status.
    $('#selections-img img').toggleClass('red-filter');
}


// Selector for the layout controls, typically the save button in the UI.
const save = $('#layout-controls');

// Add an asynchronous event handler for the 'click' event on the save button.
export default save.on('click', async function () {
    // If there is a user selection text present, remove it to clear the UI.
    if (document.querySelector(".user-selection-text")) {
        document.querySelector(".user-selection-text").remove();
    }
    // Call the saveCurrentSelectionToSession function to save the current state.
    // The function is expected to return an object with a property 'currentLayout'.
    let {currentLayout} = await saveCurrentSelectionToSession(true, 'save_selection');
    // Spread the 'currentLayout' into a new object to be used as options for the new selection.
    let options = {...currentLayout};
    // Call constructNewSelection with the new options to create a visual representation of the saved selection.
    await constructNewSelection(options);
});

// Initialize an index counter used for assigning unique IDs to new selections.
let index = 0;

// Asynchronous function to dynamically create a visual representation of a user's selection in the UI.
async function constructNewSelection(options) {
    try {
        // Extract the necessary details from the options to construct the selection.
        let {
            id,
            layoutState,
            menuState,
            thumb,
            activeDimensions,
            menuItemAIdSelected,
            activeItems,
        } = options;

        // Log the received options for debugging purposes.
        console.log("options : ", options);

        // Select the container where the new selection will be appended.
        let selectionsContainer = document.querySelector("#user-selections");

        // Create an HTML string that will be converted into a DOM element.
        // This string includes the selection's image, its associated data, and a button to remove the selection.
        let newString = `
        <div class="layout-selection obj" id='obj_${index}' data-id='${id}' data-layout='${JSON.stringify(layoutState)}'
        data-menu='${JSON.stringify(menuState)}' data-activeItems='${JSON.stringify(activeItems)}' data-type='${menuItemAIdSelected}' data-dimensions='${JSON.stringify(activeDimensions)}'>
            <div class="img-container">
                <div class="remove-selection">
                    <button type="button" class="btn-close" aria-label="Close"></button>
                </div>
                <img src='data:image/png;base64,${thumb}' />                
            </div>
        </div>
        `;

        // Increment the global index to maintain unique IDs for each selection.
        index++;

        // Parse the HTML string to create a new DOM node.
        let newNode = new DOMParser().parseFromString(newString, 'text/html').body.childNodes[0];
        // Append the new selection node to the selections container.
        selectionsContainer.append(newNode);

        // Add event listeners to the newly created selection node for remove functionality.
        document.querySelector(`[data-id="${id}"] button`).addEventListener('click', async function (e) {
            e.stopPropagation(); // Prevent the event from bubbling up to parent elements.
            document.querySelector(`[data-id="${id}"]`).remove(); // Remove the selection from the UI.

            // Update the session saved user selections after removal.
            let sessionSavedUserSelections = $("#userSelections")[0].textContent;
            let arrayOfObjects = JSON.parse(sessionSavedUserSelections);
            // Filter out the current selection object using its ID.
            const newSavedUserSelections = arrayOfObjects.filter((options) => options.id !== id);
            // Update the session storage with the new array of user selections.
            document.querySelector("#userSelections").textContent = JSON.stringify(newSavedUserSelections);

            // Prepare the request body for removing the layout from the backend session.
            const body = JSON.stringify({
                session_prefix: JSON.parse(document.getElementById('sessionPrefix').textContent),
                id
            });

            // Send a POST request to remove the saved layout from the session.
            const url = "/remove_saved_layout/";
            const myInit = {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    "X-CSRFToken": $('input[name=csrfmiddlewaretoken]').val()
                },
                mode: 'cors',
                cache: 'default',
                body
            };

            let results = await fetch(url, myInit);
            let response = await results.json();

            // If the backend confirms the selections are empty, call a function to handle the empty state.
            if (response.length === 0) {
                voidSessionSelections();
            }
        });
        // Add a click event listener to the selection with the specific ID.
        document.querySelector(`[data-id="${id}"]`).addEventListener('click', async function (e) {
            // Capture the current layout and menu state from the clicked selection.
            let curLayout = layoutState;
            let curMenuState = menuState;
            // Retrieve the ID associated with category A from the selection.
            let getJsonId = menuItemAIdSelected;
            // Log the ID for debugging purposes.
            console.log("getJsonId : ", getJsonId);

            // Extract width and length from the active dimensions of the selection.
            const {width, length} = activeDimensions;
            // Update the input fields for width and length with the selected dimensions.
            document.querySelector("#input-width").value = +width;
            document.querySelector("#input-length").value = +length;

            // Remove 'active' class from all menu items in category A, then add it to the clicked item.
            $("#category-a .menu-item").removeClass("active");
            $(`#category-a .menu-item[data-id="${getJsonId}"]`).addClass("active");

            // Update the active codes based on the current selection's active items.
            itemActive.updateActiveCodes({activeItems: JSON.parse(this.dataset.activeitems)});

            // Function to change the images in the layout according to the current selection.
            async function changeImages() {
                // Call a function that changes images based on the current layout.
                await closureForImageChange(curLayout);

                // Fetch new JSON data for the current selection.
                let newJson = await getJson({
                    e,
                    category: 'a',
                    id: +getJsonId,
                    buildFromSession: false,
                    savedMenuState: JSON.stringify(curMenuState),
                    activeMenuItemA: getJsonId,
                    activeItems: JSON.parse(document.querySelector(`[data-id="${id}"]`).dataset.activeitems),
                    itemId: curMenuState["c"],
                    fromSelections: true
                });
                // Store the returned menu state names globally for further use.
                global.menuStateNames = newJson.menuStateWithPropNames;

                // Clear the subsequent category menu items after category A.
                for (let letter of LETTERS.slice(LETTERS.indexOf('a') + 1)) {
                    $(`#category-${letter}`).empty();
                }

                // Build menu items for the new JSON data and update the UI accordingly.
                newJson.menuData.forEach(el => {
                    // Instantiate a new MenuItem object and build it in the UI.
                    let menuItem = new MenuItem(el.id, el.active,
                        el.name, el.category, el.structure,
                        el.thumbUrl, el.images, el.subCategoryItems,
                        el.showInFrontend, el.dontShowInFrontend, el.parent);
                    menuItem.buildMenuItem();
                });
                // Remove any duplicates of the active selection indicator.
                document.querySelectorAll(`[data-id="${getJsonId}"]`)[1].remove();
            }

            // Update the UI to reflect the active state of the selected layout.
            $('.layout-selection').removeClass('active');
            this.classList.add('active');
            // Invoke the change images function to update the layout visuals.
            return changeImages();
        });

    } catch (err) {
        console.log(err);
        console.log('Function argument should be a JS object');
    }
}

// Defines a function to update the layoutState of the main image using the current layout configuration.
function closureForImageChange(currentLayout) {
    try {
        let imagesArray = [];
        // Iterates over the currentLayout object to process each layout component.
        for (let [adiaforo, divComponents] of Object.entries(currentLayout)) {
            let newObj = {};
            // Extracts image-related properties from each component.
            for (let [key, value] of Object.entries(divComponents)) {
                // Filters for specific properties related to image identification and URLs.
                if (key === "imageDivId" || key === "imageUrl") {
                    newObj[key] = value;
                    imagesArray.push(newObj); // Adds the new object to the images array.
                }
            }
        }

        // Calls replaceImages function with the array of image objects to update the UI.
        return replaceImages(imagesArray);
    } catch (e) {
        // Catches and logs any errors that occur during the process.
        console.log(e);
        console.log("manage your argument - Should be a JS Object");
    }
}

// Function to construct user selections on page load based on saved session data.
export function constructSelectionsOnload() {
    // Retrieves saved user selections from the session storage.
    let sessionSavedUserSelections = document.querySelector("#userSelections").textContent;
    // Checks if the saved selections are null or empty and calls a function to handle the void state.
    if (
        JSON.parse(sessionSavedUserSelections) === "null" ||
        JSON.parse(sessionSavedUserSelections) === null ||
        JSON.parse(sessionSavedUserSelections).length === 0
    ) {
        // If no selections are saved, display a message prompting users to save their configurations.
        voidSessionSelections();
    } else {
        // If saved selections exist, it parses them and constructs new selections for each.
        let arrayOfObjects = JSON.parse(sessionSavedUserSelections);
        arrayOfObjects.forEach(option => constructNewSelection(option));
    }
}

// Function to handle the UI state when there are no saved user selections.
function voidSessionSelections() {
    // Selects the container where user selections are displayed.
    const userSelectionContainer = document.querySelector('#selections-container');
    // Creates a new paragraph element to display a message to the user.
    const newPara = document.createElement('p');
    newPara.className = 'user-selection-text';
    // Sets the message encouraging the user to save their configuration for later retrieval.
    newPara.innerHTML = `<p class="user-selection-text">  Μπορείτε να αποθηκεύσετε την τρέχουσα 
διαμόρφωση χρησιμοποιώντας το κουμπί αποθήκευσης στα δεξιά και να την επαναφορτώσετε από εδώ ανά πάσα στιγμή!</p>`;
    // Appends the new paragraph to the user selection container to inform the user.
    userSelectionContainer.appendChild(newPara);
}
