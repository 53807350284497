// Defines a closure to manage state related to active item codes.
function itemActiveClosure() {
    // Initial state with counts of active item codes set to zero.
    let activeCodes = {
        a: 0,
        b: 0,
        c: {
            // Sub-categories with their respective counts in category 'c'.
            "Ύφασμα": {c: 0, d: 0, e: 0},
            "Μαξιλάρι": {c: 0, d: 0},
            "Ποδαράκι": {c: 0, d: 0},
            "Βάση": {c: 0, d: 0}
        }
    };

    // Function to determine if any sub-category counts are at zero.
    function checkIfEmpty() {
        let isEmpty = false; // Flag indicating if any count is zero.
        // Iterates over sub-categories to check for zero counts.
        for (const val of Object.values(activeCodes.c)) {
            for (let key in val) {
                // Sets isEmpty to true if val is not a zero
                if (val[key]) {
                    isEmpty = true;
                }
            }
        }
        return isEmpty;
    }

    // Function to update the state with new active item counts.
    function updateActiveCodes({activeItems}) {
        // Replaces current state with new active items.
        activeCodes = activeItems;
        return activeCodes;
    }

    // Function to add a default active code for empty counts in sub-categories.
    function addEmptyActiveCode({subCategory, id}) {
        // Iterates over sub-categories to populate empty counts.
        for (const val of Object.values(activeCodes.c)) {
            for (let key in val) {
                if (!val[key] && val["c"] === id && key !== 'e') {
                    val[key] =  subCategory[0].id;
                }
            }
        }
    }

    // Function to register a new active code within the state.
    function addActiveCode(category, id, name, parentCatName, parentId) {
        // Adds the active code based on the category.
        // For categories 'a' and 'b', simply add the id to the state.
        if (category === 'a' || category === 'b') {
            activeCodes[category] = +id;
        }
        // For category 'c', map the id to the respective sub-category.
        else if (category === 'c') {
            activeCodes[category][name]['c'] = +id;
        }
        // For categories 'd' or 'e', the code needs to be added hierarchically based on parent category.
        else if (category === 'd' || category === 'e') {
            // If a parent category name is provided, use it to map the id correctly.
            if (parentCatName) {
                activeCodes.c[parentCatName][category] = +id;
            } else {
                // If no parent category name is provided, find the correct sub-category by parent id.
                for (let key in activeCodes.c) {
                    if (activeCodes.c[key][category === 'd' ? 'c' : "d"] === parentId) {
                        activeCodes.c[key][category] = +id;
                    }
                }
            }
        }
    }

    // Function to expose the current active codes state.
    function showActiveCode() {
        return activeCodes;
    }

    // Exposes the inner functions to manipulate and retrieve the active codes state.
    return {checkIfEmpty, addActiveCode, showActiveCode, addEmptyActiveCode, updateActiveCodes};
}

// Export the initialized closure to allow interaction with the active item codes state.
export let itemActive = itemActiveClosure();
