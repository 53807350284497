// Importing jQuery for DOM manipulation and other necessary modules for handling UI interactions.
import $ from "jquery";
import {getJson} from "./getJson";
import replaceImages from "./imagesTools";
import {itemActive} from "./itemActiveClosure";
import {
    calcPrice,
    extraActions,
    handleCategoryA,
    setListenersOnRebuild,
    showHideInputs
} from "./UIfunctions";

// Define an array to list the menu item categories used in the UI.
const LETTERS = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
];

// Placeholder array for storing images that need to be replaced in the layout.
let imagesToReplaceArray = [];

// Define a class to represent a menu item and its functionalities.
export class MenuItem {
    // Constructor initializes a MenuItem instance with properties like ID, name, category, etc.
    constructor(
        id,
        active,
        name,
        category,
        structure,
        thumbUrl,
        images,
        subCategoryItems,
        showInFrontend,
        dontShowInFrontend,
        parentId
    ) {
        // Assign the passed arguments to the instance properties.
        this.id = id;
        this.active = active;
        this.name = name;
        this.category = category;
        this.structure = structure;
        this.thumbUrl = thumbUrl;
        this.images = images;
        this.subCategoryItems = subCategoryItems;
        this.showInFrontend = showInFrontend;
        this.dontShowInFrontend = dontShowInFrontend;
        this.parentId = parentId;
    }

    // Method to construct HTML for the menu item and append it to the correct category container.
    buildMenuItem() {
        // Check if there's an overwrite directive for the category, and append HTML accordingly.
        if (this.showInFrontend?.overwriteCategory) {
            $(`#${this.showInFrontend.overwriteCategory}`).append(this.buildHtml());
        } else $(`#category-${this.category}`).append(this.buildHtml());

        // Reconstruct menu after building the HTML to ensure listeners are set up correctly.
        this.reconstructMenuListener();
        // If the menu item has subcategory items, build those as well.
        if (this.showInFrontend?.overwriteCategory && this.subCategoryItems) {
            for (const item of this.subCategoryItems) {
                let menuItem = new MenuItem(
                    // Instantiate subcategory menu items with their respective properties.
                    item.id,
                    item.active,
                    item.showInFrontend?.name ? item.showInFrontend.name : item.name,
                    item.category,
                    item.structure,
                    item.thumbUrl,
                    item.images,
                    item.subCategoryItems,
                    item.showInFrontend,
                    item.dontShowInFrontend,
                    item.parent
                );
                menuItem.buildMenuItem();
            }
        } else {
            if (this.active && this.subCategoryItems) {
                for (const item of this.subCategoryItems) {
                    let menuItem = new MenuItem(
                        // Instantiate subcategory menu items with their respective properties.
                        item.id,
                        item.active,
                        item.showInFrontend?.name ? item.showInFrontend.name : item.name,
                        item.category,
                        item.structure,
                        item.thumbUrl,
                        item.images,
                        item.subCategoryItems,
                        item.showInFrontend,
                        item.dontShowInFrontend,
                        item.parent
                    );
                    menuItem.buildMenuItem();
                }
            }
        }
    }

// Method to construct the HTML structure for a menu item.
    buildHtml() {
        // Initialize variables to hold attribute strings and the title for category 'D'.
        let a = "";
        let categoryDTitle = "";

        // Iterate over the 'showInFrontend' properties if they exist to construct data attributes.
        if (this.showInFrontend) {
            for (let [key, value] of Object.entries(this.showInFrontend)) {
                a += `data-prop-${key}="${value}" `; // Construct data attributes for rendering.
                if (key === "code") {
                    categoryDTitle = value; // Set the title if the 'code' property is present.
                }
            }
        }

        let title = "";
        // Special logic for category 'C': Show as active only the item that matches the current state.
        if (this.category === "c") {
            // Get the active category 'A' menu item's ID.
            const activeCatA = document.querySelector(`#category-a .menu-item.active`).dataset.id;
            // Fetch the name of the active 'C' state from the global menu state names object.
            const activeMenuCState = global.menuStateNames[activeCatA].c.name;
            // If the active 'C' state name does not match this item's name, set it as inactive.
            if (activeMenuCState !== this.name) {
                this.active = false;
            }
        }

        // Update the active codes for the item if necessary.
        //if (itemActive.checkIfEmpty()) {
            itemActive.addActiveCode(this.category, this.id, this.name);
        //}
        // If the item belongs to category 'D' or 'E' and is active, update its active code.
        if ((this.category === "d" || this.category === "e") && this.active) {
            itemActive.addActiveCode(this.category, this.id, this.name, '', this.parentId);
        } else if ((this.category === "c" || this.category === "d") && !this.active) {
            // For inactive 'C' or 'D' category items, add empty active codes if necessary.
            if (itemActive.checkIfEmpty()) {
                itemActive.addEmptyActiveCode({subCategory: this.subCategoryItems, id: this.id});
            }
        }

        // Construct and return the HTML string for the menu item.
        return `
        <div 
            class='menu-item ${this.active ? "active " : ""}${this.structure ? this.structure : ""}'
            id='menu-item-${this.category}-${this.id}' 
            data-name='${this.name}'
            data-category='${this.category}'             
            data-id='${this.id}'
            ${this.category === "d" ? `title="${categoryDTitle}"` : ""}
            data-active=${!!this.active}
            ${this.showInFrontend?.styles ? `style="${this.showInFrontend.styles.map((el) => el)}"` : ""}
            ${a}
            ${title}
        >
            ${this.thumbUrl ? `<img src='${this.thumbUrl}' >` : ""}
<!--            <br class="menu-item-name-brake">  -->
            ${(this.category === 'd' || this.category === 'e') && this.thumbUrl ? '' : `<span class="menu-item-name">${this.name}</span>`}
        </div>
    `;
    }

    // Sets up event listeners for menu items to handle user interaction and dynamic content updates.
    reconstructMenuListener() {
        // Check if the current menu item is meant to overwrite a category for display purposes.
        if (this.showInFrontend?.overwriteCategory) {
            let category = this.category;
            // Select the current menu item by its ID and add a click event listener.
            document.querySelector(`#menu-item-${category}-${this.id}`).addEventListener("click", async function (e) {
                // If the menu item is already active, do nothing.
                if (this.classList.contains("active")) return;

                // If the event is a user action, remove the active state from other layout selections.
                if (e.isTrusted) {
                    $(".layout-selection").removeClass("active");
                }
                // If the menu item isn't currently active, make it active.
                if (!this.classList.contains("active")) {
                    $(this).siblings().removeClass("active"); // Remove active class from sibling elements.
                    $(this).addClass("active"); // Add active class to this element.

                    // Fetch JSON data for the new menu state.
                    await getJson({
                        e,
                        category: this.dataset.category,
                        id: this.dataset.id
                    }).then((data) => {
                        let menuData = data.menuData;
                        let {layoutState} = data;
                        console.log(data);
                        replaceImages(Object.values(layoutState)); // Update the image layout based on the new state.

                        // Clear sub-item menus if this item overwrites a background category.
                        if (this.dataset.propOverwritecategory === "background-subcategories") {
                            // Logic to remove elements from the background floors, if necessary, goes here.
                        } else if (this.dataset.propOverwritecategory !== "background-floors") {
                            // For other categories, empty the sub-item menus.
                            for (let letter of LETTERS.slice(LETTERS.indexOf(category) + 1)) {
                                $(`#category-${letter}`).empty(); // Empty the categories that follow this one.
                            }
                        }
                        // If the JSON data contains subcategory items, build menu items for them.
                        if (menuData[0].subCategoryItems) {
                            menuData[0].subCategoryItems.forEach((el) => {
                                let menuItem = new MenuItem(
                                    el.id,
                                    el.active,
                                    el.showInFrontend?.name ? el.showInFrontend.name : el.name,
                                    el.category,
                                    el.structure,
                                    el.thumbUrl,
                                    el.images,
                                    el.subCategoryItems,
                                    el.showInFrontend,
                                    el.dontShowInFrontend,
                                    el.parent,
                                );
                                menuItem.buildMenuItem(); // Build each subcategory menu item.
                            });
                        }
                        // Images to replace in the layout are handled here.
                    });
                }

                // Set listeners again on items after rebuilding the menu to ensure they are responsive to user actions.
                setListenersOnRebuild();
            });

        } // Else block to handle non-overwriting categories within the menu.
        else {
            let category = this.category;
            // If no category is defined, exit the function to avoid errors.
            if (category === undefined) {
                return;
            }
            // Add a click event listener to the current menu item based on its category and ID.
            document.querySelector(`#menu-item-${category}-${this.id}`).addEventListener("click", async function (e) {
                // Locate the active menu item within the parent container.
                const activeMenuItem = this.parentNode.parentNode.childNodes[0].nextSibling.querySelector('.menu-item.active');
                // Get the name of the parent category, if available.
                const parentCatName = activeMenuItem?.dataset.name;

                // Update the active codes, which keep track of which items are selected.
                if (parentCatName) {
                    itemActive.addActiveCode(category, this.dataset.id, this.dataset.name, parentCatName);
                } else {
                    itemActive.addActiveCode(category, this.dataset.id, this.dataset.name);
                }

                // If the clicked menu item is already active, do nothing further.
                if (this.classList.contains("active")) return;
                // If the click event was user-generated, remove the active class from other layout selections.
                if (e.isTrusted) {
                    $(".layout-selection").removeClass("active");
                }

                // If the menu item is not already active, perform the following actions.
                if (!this.classList.contains("active")) {
                    extraActions($(this)); // Perform additional actions defined in the extraActions function.
                    // Toggle the active class on this menu item, and ensure siblings are not active.
                    $(this).siblings().removeClass("active");
                    $(this).addClass("active");

                    // Fetch JSON data for the new state and update the menu accordingly.
                    await getJson({
                        e,
                        category: this.dataset.category,
                        id: this.dataset.id
                    }).then((data) => {
                        let {menuData} = data;
                             console.log(data);
                        // Store the menu state names globally for reference.
                        global.menuStateNames = data.menuStateWithPropNames;
                        // Update the layout images to reflect the new state.
                        let {layoutState} = data;
                        replaceImages(Object.values(layoutState));
                        // Clear any items in the sub-categories following this one.
                        for (let letter of LETTERS.slice(LETTERS.indexOf(category) + 1)) {
                            $(`#category-${letter}`).empty();
                        }
                        // If the menu data contains sub-category items, build these menu items as well.
                        if (menuData[0].subCategoryItems) {
                            menuData[0].subCategoryItems.forEach((el) => {
                                let menuItem = new MenuItem(
                                    el.id,
                                    el.active,
                                    el.showInFrontend?.name ? el.showInFrontend.name : el.name,
                                    el.category,
                                    el.structure,
                                    el.thumbUrl,
                                    el.images,
                                    el.subCategoryItems,
                                    el.showInFrontend,
                                    el.dontShowInFrontend,
                                    el.parent
                                );
                                menuItem.buildMenuItem(); // Construct each sub-category menu item.
                            });
                        }
                    });
                }

                await calcPrice();
                // If the category is 'A' or 'B', handle the special logic for these categories.
                if (category === "b" || category === "a") {
                    handleCategoryA();
                }
                // If the category is 'A', update the bed text to reflect the current selection.
                if (category === "a") {
                    document.querySelector("#bed-text").textContent = $("#category-b .menu-item.active").attr("data-prop-bed-text");
                }

                showHideInputs();
                console.log(itemActive.showActiveCode());
                // Set up listeners again for the new menu structure to ensure they respond to user interaction.
                setListenersOnRebuild(category);
                // A placeholder for a function that calculates the price, possibly based on the current range selection.
                // await calcPrice(document.querySelector('#connect-range'));
                // A call to a function that retrieves properties related to the active state, commented out for future use.
                //getActiveStateProps();
            });
        }
    }
}
