import $ from "jquery";

// Defines a function to perform a GET request using the custom 'request' function.
export const getRequest = (url, params, options) =>
    request(url, params, options, "GET");

// Defines a function to perform a POST request using the custom 'request' function.
export const postRequest = (url, params, options) =>
    request(url, params, options, "POST");

// General purpose function to handle both GET and POST requests.
const request = (url, params = {}, options = {}, method = "GET") => {
    // Set the method type in the options object.
    options.method = method;
    // If the request is a GET request, append query parameters to the URL.
    if ("GET" === method) {
        url += "?" + new URLSearchParams(params).toString();
    } else {
        // For POST requests, set the body to a stringified version of parameters, and include CSRF token.
        options.body = JSON.stringify(params);
        options.credentials = "same-origin";
        options.headers = {
            "X-CSRFToken": $("input[name=csrfmiddlewaretoken]").val(),
        };
    }

    // Check if the URL is for a PDF retrieval and handle it differently.
    if (!url.includes("get-pdf")) {
        // For non-PDF fetches, return a promise that resolves to the JSON response.
        return fetch(url, options)
            .then((response) => response.json()) // Convert the response to JSON.
            .catch((err) => console.log("error", err)); // Log errors to the console.
    } else {
        // For PDF fetches, process the response to download the file.
        return fetch(url, options)
            .then((response) => {
                const disposition = response.headers.get('Content-Disposition');
                // Extract the filename from the Content-Disposition header.
                const filename = disposition
                    ? /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition)[1]
                    : '';
                // Convert the response to a blob and create a local URL for it.
                response.blob().then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url; // Set the href to the blob URL.
                    a.download = filename.replace(/['"]/g, ''); // Remove any quotes from the filename.
                    document.body.appendChild(a); // Append the anchor tag to the document.
                    a.click(); // Programmatically click the anchor tag to start download.
                    a.remove(); // Remove the anchor tag from the document.
                });
            })
            .catch((err) => console.log("error", err)); // Log errors to the console.
    }
};
