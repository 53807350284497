import $ from "jquery";
import {sessionPrefix} from "./index";
import {DIMENSIONS, LENGTH_INPUT, WIDTH_INPUT} from "./consts";
import {getRequest, postRequest} from "./requests";
import Swal from "sweetalert2";
import {itemActive} from "./itemActiveClosure";
// import {sendStatisticToServer} from "./helpers";

let [connectRange] = $("#connect-range");

// window.addEventListener("resize", () => {
//     let width = window.innerWidth;
//     if (width > 1200) {
//         // $("#starting-price-container").hasClass("hidden") ?
//         //     $("#user-buttons-container").css("width", "0px")
//         //     : $("#user-buttons-container").css("width", "0px");
//         // $("#starting-price-container").hasClass("hidden") ?
//         //     $("#selections-container").css("width", "410px")
//         //     : $("#selections-container").css("width", "410px").css("left", "-255px");
//
//     } else {
//         $("#user-buttons-container").css("width", "100%");
//         $("#selections-container").css("width", "100%").css("left", "0px");
//     }
// });

function createObjectForPostRequest(modalId) {
    const namesToProps = {
        "Fabric Meters": "fabricMeters",
        "Fabric Pillow Meters": "fabricPillowMeters",
        "Num of Bedfeet": "numOfBedfeet"
    };

    // build the object from the name and inputs of the modal body
    const data = {
        showInFrontend: {},
        dontShowInFrontend: {}
    };
//    get the modal non-editable-area
    const showInFrontend = $(`${modalId} .modal-body .showInFrontend`),
        dimensionsPricesNodeList = $(`${modalId} .modal-body .dimensionsPrices`),
        fasaNodeList = $(`${modalId} .modal-body .fasa`),
        dimensionsBedfeetNodeList = $(`${modalId} .modal-body .dimensionsBedfeet`);
    // showInfrontendArray and dontShowInfrontendArray
    const showInFrontendArray = [
            "name",
            "max-width",
            "min-width",
            "width-step",
            "max-length",
            "min-length",
            "length-step",
            "price",
            "code",
            "color",
            "category",
            "subCategory",],
        dontShowInFrontendArray = [
            "width",
            "price",
            "Fabric Meters",
            "Num of Bedfeet",
            "Fabric Pillow Meters"
        ];

    showInFrontend.each(function (index, element) {
        let elementsTextContent = element.textContent.trim();
        let elementsFirstToLowerCase = elementsTextContent[0].toLowerCase() + elementsTextContent.substring(1);
        let value = $(this).parent().parent().children('.col-7').children().val();
        if (showInFrontendArray.includes(elementsFirstToLowerCase) && elementsFirstToLowerCase !== "price") {
            data.showInFrontend[elementsFirstToLowerCase] = value;
        } else if (showInFrontendArray.includes(elementsFirstToLowerCase)
            && elementsFirstToLowerCase === "price") {
            // console.log("$('#input-color-price').prop(\"disabled\")", $('#input-color-price').prop("disabled"));
            data.showInFrontend[elementsFirstToLowerCase] = $('#input-color-price').prop("disabled") ? null : Number(value);
        }
        // data.showInFrontend
    });

    if (dimensionsPricesNodeList.length > 0) {
        data.dontShowInFrontend["dimensionsPrices"] = [];
        const {dimensionsPrices} = data.dontShowInFrontend;

        // loop through all the elements in the nodelist for dimensionsPrices
        dimensionsPricesNodeList.each(function (index, element) {
            let elementsTextContent = element.textContent.trim();
            let elementTextContentToLowerCase = elementsTextContent[0].toLowerCase() + elementsTextContent.substring(1);
            let value = $(this).parent().parent().children('.col-7').children().val();
            let arraysIndex = Number($(this).attr("data-index"));
            // console.log(dimensionsPrices[$(this)], $(this).attr("data-index"));
            if (!dimensionsPrices[arraysIndex]) {
                dimensionsPrices[arraysIndex] = {};
            }

            if (dontShowInFrontendArray.includes(elementsTextContent)
                && elementsTextContent in namesToProps) {
                dimensionsPrices[arraysIndex][namesToProps[elementsTextContent]] = parseFloat(value);
            } else if (dontShowInFrontendArray.includes(elementTextContentToLowerCase)) {
                dimensionsPrices[arraysIndex][elementTextContentToLowerCase] = parseFloat(value);
            }
        });
    }

    if (fasaNodeList.length > 0) {
        data.dontShowInFrontend["fasa"] = {};
        const {fasa} = data.dontShowInFrontend;

        // loop through all the elements in the nodelist for fasa
        fasaNodeList.each(function (index, element) {
            let elementsTextContent = element.textContent.trim();
            let elementTextContentToLowerCase = elementsTextContent[0].toLowerCase() + elementsTextContent.substring(1);
            let value = $(this).parent().parent().children('.col-7').children().val();
            // console.log(namesToProps[elementsTextContent]);
            if (dontShowInFrontendArray.includes(elementsTextContent)
                && elementsTextContent in namesToProps) {
                fasa[namesToProps[elementsTextContent]] = parseFloat(value);
            } else if (dontShowInFrontendArray.includes(elementTextContentToLowerCase)) {
                fasa[elementTextContentToLowerCase] = parseFloat(value);
            }
        });
    }

    if (dimensionsBedfeetNodeList.length > 0) {
        data.dontShowInFrontend["dimensionsBedfeet"] = [];
        const {dimensionsBedfeet} = data.dontShowInFrontend;

        // loop through all the elements in the nodelist for dimensionsPrices
        dimensionsBedfeetNodeList.each(function (index, element) {
            let elementsTextContent = element.textContent.trim();
            let elementTextContentToLowerCase = elementsTextContent[0].toLowerCase() + elementsTextContent.substring(1);
            let value = $(this).parent().parent().children('.col-7').children().val();
            let arraysIndex = Number($(this).attr("data-index"));
            if (!dimensionsBedfeet[arraysIndex]) {
                dimensionsBedfeet[arraysIndex] = {};
            }
            if (dontShowInFrontendArray.includes(elementsTextContent)
                && elementsTextContent in namesToProps) {

                dimensionsBedfeet[arraysIndex][namesToProps[elementsTextContent]] = parseFloat(value);
            } else if (dontShowInFrontendArray.includes(elementTextContentToLowerCase)) {
                dimensionsBedfeet[arraysIndex][elementTextContentToLowerCase] = parseFloat(value);
            }
        });
    }


    const {id, name} = $("#context-menu-content #context-menu-edit-item").data().set;
    if (name !== data.showInFrontend.name) {
        $(`[data-id="${id}"] span`).text(data.showInFrontend.name);
    }
    // console.log('data', data);
    return data;
}

async function createModalContent(options) {
    const saveButton = $('#edit-modal > div > div > div.modal-footer > button.btn.btn-primary');
    const propsToNames = {
        fabricMeters: "Fabric Meters",
        fabricPillowMeters: "Fabric Pillow Meters",
        numOfBedfeet: "Num of Bedfeet"
    };

    if (saveButton.attr('disabled'))
        saveButton.removeAttr('disabled');

    const {category, id, name} = options;
    // console.log(category, id);

    $("#edit-modal-title").html("").append("Επεξεργασία του " + name);
    const content = `
    <div id="non-editable-content" class="container mb-4">
        
    </div>
    <div id="editable-content" class=" container">
    
    </div>
    `;
    const URLopts = {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            "X-CSRFToken": $('input[name=csrfmiddlewaretoken]').val()
        }
    };
    const Url = `/gpoi/?id=${id}&category=${category}`;

    let res = await fetch(Url, URLopts);
    const {properties, priceOfParent} = await res.json();
    // console.log("data", properties);
    $("#edit-modal > div > div > div.modal-body").html(content);

    switch (category) {
        case "a": {
            $('#editable-content').remove();
            let nonEditableContent = $("#non-editable-content");
            for (let [key, value] of Object.entries(options)) {
                nonEditableContent[0].innerHTML += `
                <div class="card">
                    <div class="card-body">
                        <div class="row w-100">
                           <div class="col-5">
                            <p class="non-editable-item-name">${key[0].toUpperCase() + key.substring(1)}</p>
                            </div>
                            <div class="col-7 text-end">
                            <p class="non-editable-value">${value}</p>
                            </div>
                        </div>
                    </div>
                </div>
                `;
                saveButton.attr('disabled', "true");
            }
            break;
        }
        default : {
            let nonEditableContent = $("#non-editable-content"),
                editableContent = $("#editable-content");
            const priceCheckbox = `<div class="form-check" style="margin-right:30px;margin-left: auto;width: fit-content;">
              <input class="form-check-input" type="checkbox" id="unlock-price">
              <label class="form-check-label" for="unlock-price">
                Άλλαξε την τιμή
              </label>
                            </div>`;
            const returnToparentPrice = `<div class="form-check" style="margin-right:30px;margin-left: auto;width: fit-content;">
              <input class="form-check-input" type="checkbox" id="parent-price">
              <label class="form-check-label" for="parent-price">
                Επαναφορά στην τιμή του υφάσματος
              </label>
                            </div>`;
            for (let [key, value] of Object.entries(options)) {
                if (key === "id" ||
                    key === "category"
                )
                    nonEditableContent[0].innerHTML += `
                <div class="card">
                    <div class="card-body">
                        <div class="row w-100">
                           <div class="col-5">
                            <p class="non-editable-item-name">${key[0].toUpperCase() + key.substring(1)}</p>
                            </div>
                            <div class="col-7 text-end">
                            <p class="non-editable-value" data-id='${key === "category" ? "category" : "id"}'>${value}</p>
                            </div>
                        </div>
                    </div>
                </div>`;
            }
            for (let [key, value] of Object.entries(properties.showInFrontend)) {
                editableContent[0].innerHTML += `
                    <div class="card">
                        <div class="card-body">
                            <div class="row w-100">
                               <div class="col-5">
                                <p class="editable-item-name showInFrontend" >
${key in propsToNames ? propsToNames[key] : key[0].toUpperCase() + key.substring(1)}
</p>
                                </div>
                                <div class="col-7 text-end form-group">
                                <input ${key === 'price' ? 'id="input-color-price"' : ""}  type =${isNaN(Number(value)) ? "text" : "number"} class="editable-value form-control text-end"  value="${value || priceOfParent}"   
${key === "overwriteCategory" || (category === "d" && key === "price" && value === null) ? "disabled" : ""}/>
                                </div>
                            </div>
                  ${category === "d" && key === "price" && value === null ? priceCheckbox :
                    category === "d" && key === "price" && value !== null ? returnToparentPrice
                        : ""}
                        </div>
                    </div>
                    `;
            }
            editableContent.children().last().addClass('mb-5');
            const dontShowInFrontend = properties.dontShowInFrontend;
            if (dontShowInFrontend.dimensionsPrices) {
                editableContent[0].innerHTML += `
                    <div class="container">
                            <div class="row w-100">
                               <div class="col-12">
                                <p class="editable-item-name lead ">
                                Dimension Prices:
</p>
<p class="lead"><sub>*Every group represents the width above which a certain value is applied.</sub></p>

                                </div>
                            </div>
                    </div>
                    `;
                let index = 0;
                dontShowInFrontend.dimensionsPrices.forEach(object => {

                    for (let [key, value] of Object.entries(object)) {
                        editableContent[0].innerHTML += `
                    <div class="card">
                        <div class="card-body">
                            <div class="row w-100">
                               <div class="col-5">
                                <p class="editable-item-name dimensionsPrices" data-index ="${index}">
${key in propsToNames ? propsToNames[key] : key[0].toUpperCase() + key.substring(1)}
</p>
                                </div>
                                <div class="col-7 text-end form-group">
                                <input type="number" class="editable-value form-control text-end" value="${value}" />
                                </div>
                            </div>
                        </div>
                    </div>
                    `;
                    }
                    index++;
                    editableContent.children().last().addClass('mb-5');
                });
            }

            if (dontShowInFrontend.fasa) {
                editableContent[0].innerHTML += `
                    <div class="container">
                            <div class="row w-100">
                               <div class="col-12">
                                <p class="editable-item-name lead">
                                Fasa*:
</p>
<p class="lead"><sub>*It represents the fabric meters needed to cover fasa its price (fabric not included).</sub></p>
                            </div>
                        </div>
                    </div>
                    `;

                for (let [key, value] of Object.entries(dontShowInFrontend.fasa)) {
                    editableContent[0].innerHTML += `
                    <div class="card">
                    <div class="card-body">
                            <div class="row w-100">
                               <div class="col-5">
                                <p class="editable-item-name fasa">
${key in propsToNames ? propsToNames[key] : key[0].toUpperCase() + key.substring(1)}
</p>
                                </div>
                                <div class="col-7 text-end form-group">
                                <input type="number" class="editable-value form-control text-end" value="${value}" />
                                </div>
                        </div>
                        </div>
                    </div>
                    `;
                }
                editableContent.children().last().addClass('mb-5');
            }
            if (dontShowInFrontend.dimensionsBedfeet) {
                editableContent[0].innerHTML += `
                    <div class="container">
                            <div class="row w-100">
                               <div class="col-12">
                                <p class="editable-item-name lead">
                                Bed Feet Dimensions*:
</p>
<p class="lead"><sub>*Every batch of two represents the width above which a certain number of bed feet is applied.</sub></p>
                                </div>
                        </div>
                    </div>
                    `;
                let index = 0;
                dontShowInFrontend.dimensionsBedfeet.forEach(object => {
                    // console.log(object);
                    for (let [key, value] of Object.entries(object)) {
                        editableContent[0].innerHTML += `
                    <div class="card">
                        <div class="card-body">
                            <div class="row w-100">
                               <div class="col-5">
                                <p class="editable-item-name dimensionsBedfeet" data-index ="${index}">
                                
${key in propsToNames ? propsToNames[key] : key[0].toUpperCase() + key.substring(1)}
</p>
                                </div>
                                <div class="col-7 text-end form-group">
                                <input type="number" class="editable-value form-control text-end" value="${value}" />
                                </div>
                            </div>
                        </div>
                    </div>
                    `;
                    }
                    index++;
                    editableContent.children().last().addClass('mb-5');
                });
            }
        }
            break;
    }
    const epanafora = "Επαναφορά της τιμής για όλα τα αντικείμενα του ίδιου είδους",
        enimerosi = "Ενημέρωσε την τιμή για όλα τα αντικείμενα του ίδιου είδους";

    $("#editable-content").children().last().removeClass("mb-5");

    if (category === "c" || category === "d") {
        let editableContent = $("#edit-modal .modal-body");

        editableContent[0].innerHTML += `
            <div class="container row mt-3 ${category === "d" ? "hidden" : ""}" id="check-button-change-price">
                <div class="form-check ">
  <input class="form-check-input" type="checkbox" value="" id="change-all">
  <label class="form-check-label" for="change-all">
    ${properties.showInFrontend?.price && category === "d" ? epanafora : enimerosi}
  </label>
</div>
            </div>
        `;

        if ($('#unlock-price').length > 0) {
            $('#unlock-price').off('change').on('change', function () {
                if ($("#unlock-price").prop('checked')) {
                    $("#input-color-price").removeAttr('disabled');
                    $("#check-button-change-price").removeClass('hidden');
                } else {
                    $("#input-color-price").attr('disabled', 'disabled').val(priceOfParent);
                    $("#check-button-change-price").addClass('hidden');
                }
            });
        }
        if ($('#parent-price').length > 0) {
            $('#parent-price').change(function () {
                if (this.checked) {
                    $("#input-color-price").attr('disabled', 'disabled').attr('value', priceOfParent);
                    $("#check-button-change-price").removeClass('hidden');
                } else {
                    $("#input-color-price").removeAttr('disabled');
                    $("#check-button-change-price").addClass('hidden');
                }
            });
        }
    }
}

async function calcPrice() {
    $("#price-label").addClass("hidden");
    $(".price-loader").show();

    const activeA = document.querySelector("#category-a .active");
    const activeAId = activeA.dataset.id;
    const trithesios = document.querySelector("#input-trithesios-checkbox").checked;
    const dithesios = document.querySelector("#input-dithesios-checkbox").checked;
    const totalPriceDiv = document.querySelector("#price-label");
    totalPriceDiv.classList.add("hidden");

    let price = await postRequest("/athanasiou/calc-price/", {
        sessionPrefix: sessionPrefix,
        activeAId,
        checkboxes: [trithesios, dithesios]
    });

    let {
        totalPrice,
        debugInfo
    } = price;


    totalPriceDiv.textContent = totalPrice + "€";


    $(".price-loader").fadeOut(function () {
        $("#price-container").fadeIn();
        totalPriceDiv.classList.remove("hidden");

    });

    $("#price-debug-info").html(debugInfo);
}

function hideBackgroundButton() {
    $('#category-a [data-name="Background"]').hide();
}

function scrollMenuToTop() {
    document.querySelector(".grid-column1").scrollTop = 0;
}

function disappearContextMenu() {
    if (document.querySelector("#context-menu-container")) {
        $("#context-menu-container").addClass('hidden');
    }
}

function handleRightClickMenu(e) {
    e.preventDefault();
    const {clientX: mouseX, clientY: mouseY} = e;
    $("#context-menu-container").css('top', mouseY).css('left', mouseX).css("z-index", 10000).removeClass('hidden');
    $("#context-menu-edit-item").attr("data-set", JSON.stringify(this.dataset));
}

function handleEditModalBuild() {
    const edit = document.querySelector('#context-menu-edit-item');
    $('#edit-modal .modal-body').html('');
    if (edit.dataset.set === "{}") {
        // console.log(edit.dataset.set);
        return null;
    } else {
        //    create the inner HTML content
        let set = JSON.parse(edit.dataset.set);
        createModalContent(set);
    }

}

function addListenersOnButtons() {
// set save button of edit-properties-modal
    const saveButton = $('#edit-modal > div > div > div.modal-footer > button.btn.btn-primary');
    // set Url to call when savebutton is pressed
    const urlChangeProperties = `/core/update-props-of-object/`;

    saveButton.click(async function () {
        const body = JSON.stringify({
            category: $("[data-id='category']").text(),
            id: $("[data-id='id']").text(),
            properties: createObjectForPostRequest('#edit-modal'),
            changeAll: !!$('#change-all').prop('checked')
        });
        // console.log(body);
        const changeUrlOpts = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
                "X-CSRFToken": $('input[name=csrfmiddlewaretoken]').val(),
            },
            body
        };

        let response = await fetch(urlChangeProperties, changeUrlOpts);
        let result = await response.json();
        // console.log(result);
        if (result.success) {
            const Toast = Swal.mixin({
                toast: true,
                text: "Successfully Saved Your Modifications",
                position: "bottom-right",
                iconColor: "white",
                customClass: {
                    popup: "colored-toast",
                },
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            });
            await Toast.fire({
                icon: "success",
                title: "Hooray!",
            });
        } else {
            const Toast = Swal.mixin({
                toast: true,
                text: "Something Went Wrong!",
                position: "bottom-right",
                iconColor: "white",
                customClass: {
                    popup: "colored-toast",
                },
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            });
            await Toast.fire({
                icon: "error",
                title: "Oops!",
            });
        }

    });


    // let headboardsId = document.querySelector('#category-a [data-name=' + HEADBOARD_MENU_NAME + ']').dataset.id;
    //add listener to create new data for excel file
    const pngClicked = document.querySelector("#form-get-pdf");
    pngClicked.addEventListener("click", async function (e) {
        const dithesiosCheckbox = document.querySelector("#input-trithesios-checkbox").checked;
        const trithesiosCheckbox = document.querySelector("#input-dithesios-checkbox").checked;

        e.preventDefault();
        let sessionPrefix = JSON.parse(document.getElementById('sessionPrefix').textContent);
        let url = `/athanasiou/get-pdf/`;
        await getRequest(url, {
            sessionPrefix,
            dimension_state:  JSON.stringify({
                width: document.getElementById('input-width').value,
                length: document.getElementById('input-length').value,
                dithesios: dithesiosCheckbox ? document.getElementById('input-dithesios').value + ' εκ' : 'Empty',
                trithesios: trithesiosCheckbox ? document.getElementById('input-trithesios').value + ' εκ' : 'Empty',
            }),
            set_type: JSON.stringify({
                active: document.querySelector("[data-name='Καναπές Σετ']").classList.contains("active"),
                trithesios: trithesiosCheckbox,
                dithesios: dithesiosCheckbox
            }),
            activeItems: JSON.stringify(itemActive.showActiveCode())
        });
    });


    const icon = document.querySelector(".icon"),
        burgerMenu = document.querySelector("#burger-menu");

    icon.addEventListener("click", (event) => {
        console.log("clicked");
        icon.classList.toggle("open");
        if (burgerMenu.classList.contains("hidden-menu")) {
            burgerMenu.style.bottom = '90px';
            burgerMenu.classList.toggle("hidden-menu");
            setTimeout(() => {
            burgerMenu.style.zIndex = "initial";
            }, 300);

        } else {
            burgerMenu.style.zIndex = "-1";
            burgerMenu.style.bottom = '0px';
            burgerMenu.classList.toggle("hidden-menu");
        }
    });

    window.addEventListener("resize", function () {
        let width = window.innerWidth;
        let height = window.innerHeight;
        // if (width > 1200 && height > width) {
        //     $(".image-display").css("background-size", "contain");
        // } else {
        //     $(".image-display").css("background-size", "cover");
        // }
    });

    document.getElementById(`${WIDTH_INPUT}`).addEventListener("change", function () {
        DIMENSIONS.width = this.value;
        // console.log(DIMENSIONS);
    });
    document.getElementById(`${LENGTH_INPUT}`).addEventListener("change", function () {
        console.log(this.value);
        DIMENSIONS.length = this.value;
        console.log(DIMENSIONS);
    });
}

export function handleCategoryA() {
    $(".dimensions.container").removeClass("disabled");

    let bedTextContainers = $(".bed-text-container"),
        activeBItem = $("#category-b .active")[0];

    bedTextContainers.addClass("hidden");
}

function setListenersOnRebuild(nextCategory) {
    if (JSON.parse($('#is_superuser').text())) {
        $(".menu-item").off('contextmenu').contextmenu(handleRightClickMenu);
        document.querySelector('body').removeEventListener('click', disappearContextMenu);
        document.querySelector('body').addEventListener("click", disappearContextMenu);
        $('#context-menu-edit-item').off('click').click(handleEditModalBuild);
    }

    document.querySelector("#headboards-tooltip")?.classList.add("hidden");
    // Set listener to base change.
    if (nextCategory === "a" || nextCategory === undefined) {
        // Set listener to base change.

        $("#category-b .menu-item").on("click", function (e) {

            if (this.dataset.propName === "Tivoli") {
                $("#add-pillows").removeClass("hidden");
                document.querySelector(".add-pillows").classList.remove("hidden");
                document.querySelector("#add-pillows input").checked = false;

                if (this.dataset?.propSubcategoryname) {
                    $("#category-b .menu-item[data-name='Tivoli']").addClass('sub-category-active');
                } else {
                    $("#category-b .menu-item[data-name='Tivoli']").removeClass('sub-category-active');
                }
            } else {
                $("#category-b .menu-item[data-name='Tivoli']").removeClass('sub-category-active');
                $("#add-pillows").addClass("hidden");
                document.querySelector(".add-pillows")
                    .classList
                    .add("hidden");
                document.querySelector("#tivoli-subcategories").classList.add("hidden");
            }

        });
        // Listener for the Tivoli subcategories
    } else if (nextCategory === "b") {

        $("#tivoli-subcategories .menu-item").on("click", function (e) {
            $("#category-b .menu-item[data-name='Tivoli']")
                .addClass('sub-category-active');
            $("#category-b .menu-item").removeClass("active");
        });
        categoryCMenuItemListener();
    }
}

function categoryCMenuItemListener() {

    $("#category-c .menu-item").on("click", async function () {
        // Get categories d dataset category and subcategory
        let selectedValues = document.querySelectorAll(
            ".vscomp-dropbox-container.pop-comp-wrapper.position-bottom > div > div.vscomp-options-container > div.vscomp-options-list > div .selected"
        );
        setTimeout(function () {
            const categoryDMenuItems = document.querySelectorAll(
                "#category-d .menu-item"
            );
            categoryDMenuItems.forEach((item) => {
                let {propCategory, propSubcategory} = item.dataset;
                for (let value of selectedValues) {
                    let val = value.dataset.value;
                    if (val === propCategory || val === propSubcategory) {
                        item.style.display = "initial";
                    } else {
                        item.style.display = "none";
                    }
                }
            });
        }, 250);
    });
}

function extraActions(t) {
    if (t.attr("data-category") === "a") {
        $("#bed-feet").addClass("hidden");
        $("#category-b").removeClass("hidden");
        enableDisableFabricsMenus(true);
    }
}

function enableDisableFabricsMenus(enableDisable) {
    let class_ = "hidden";
    if (enableDisable) {
        $("#bed-attribute").removeClass(class_);
        $("#property-selections").removeClass(class_);
        $("#category-c").removeClass(class_);
        $("#category-d").removeClass(class_);
        $("#breadcrumbs").removeClass(class_);
    } else {
        $("#bed-attribute").addClass(class_);
        $("#property-selections").addClass(class_);
        $("#category-c").addClass(class_);
        $("#category-d").addClass(class_);
        $("#breadcrumbs").addClass(class_);
    }
}

function validateInputs() {
    document.getElementById('input-width').addEventListener('change', function (e) {
        let width = e.target.value;
        if (width < 280) {
            e.target.value = 280;
        } else if (width > 320) {
            e.target.value = 320;
        }
    });
    document.getElementById('input-length').addEventListener('change', function (e) {
        let length = e.target.value;
        if (length < 200) {
            e.target.value = 200;
        } else if (length > 240) {
            e.target.value = 240;
        }
    });

    document.getElementById('input-dithesios').addEventListener('change', function (e) {
        let dithesios = e.target.value;
        if (dithesios < 170) {
            e.target.value = 170;
        } else if (dithesios > 190) {
            e.target.value = 190;
        }
    });

    document.getElementById('input-trithesios').addEventListener('change', function (e) {
        let trithesios = e.target.value;
        if (trithesios < 220) {
            e.target.value = 220;
        } else if (trithesios > 250) {
            e.target.value = 250;
        }
    });
}

function sendCheckboxesToServer() {
    let checkboxes = document.querySelectorAll(".set-dimesions-checkboxes");
    let lastChecked = null; // Variable to keep track of the last checked checkbox

    checkboxes.forEach((checkbox) => {
        checkbox.addEventListener("change", async function (e) {
            if (this.checked) {
                // If a checkbox is checked, remember it as the last checked
                lastChecked = this;
            } else if (!document.querySelector(".set-dimesions-checkboxes:checked")) {
                // If trying to uncheck and no other checkbox is checked, re-check this one and return
                this.checked = true;
                return;
            }
            await calcPrice();
        });
    });
}


function showHideInputs() {
    if (document.querySelector('[data-name="Καναπές Γωνία"]').classList.contains('active')) {
        document.querySelector('#input-container').style.display = 'flex';
        document.querySelector('#input-container-set').style.display = 'none';
    } else if (document.querySelector('[data-name="Καναπές Σετ"]').classList.contains('active')) {
        document.querySelector('#input-container').style.display = 'none';
        document.querySelector('#input-container-set').style.display = 'flex';
    }
}

export {
    enableDisableFabricsMenus,
    extraActions,
    hideBackgroundButton,
    addListenersOnButtons,
    setListenersOnRebuild,
    scrollMenuToTop,
    validateInputs,
    showHideInputs,
    calcPrice,
    sendCheckboxesToServer
};
