/*
 * The layout_type can be either multi-layout or single-layout.
 * (Doppler is multi-layout and MediaStrom is single-layout)
 *
*/
import {orderId, sessionPrefix} from "./index";
import $ from "jquery";
import {getRequest} from "./requests";
import {itemActive} from "./itemActiveClosure";
import {DIMENSIONS} from "./consts";

export async function getJson({
                                  e,
                                  category = 'a',
                                  id = 1,
                                  buildFromSession = false,
                                  layoutType,
                                  layoutName,
                                  savedMenuState = '',
                                  activeMenuItemA = "",
                                  activeItems,
                                  itemId = 0,
                                  // from selections is used to let the server know that
                                  // the request is coming from the selections page
                                  fromSelections = false
                              }) {
    /*
     * You can call getJson('all', 0, true) to get all the menu.
     * You can call getJson('c', 3) to get the menu from CategoryC with menuItemID=3 and below.
    */
    let menuItemASelected; // Variable to store the selected menu item in category 'A'.
    // Logic to determine the correct menuItemASelected based on various conditions and active states.
    if (e.target?.dataset?.propOverwritecategory === "background-subcategories"
        || e.target?.parentNode?.dataset?.propOverwritecategory === "background-subcategories"
        || e.target?.dataset?.propOverwritecategory === "background-floors"
        || e.target?.parentNode?.dataset?.propOverwritecategory === "background-floors") {
        menuItemASelected = activeMenuItemA ? activeMenuItemA : $('#category-a .menu-item.active').attr('data-id');
    } else {
        menuItemASelected = activeMenuItemA ? activeMenuItemA : $('#category-a .menu-item.active').attr('data-id');
    }
    // The getRequest function is called to make a request to the backend with the given parameters.
    return await getRequest(
        '/get-my-json/', // The endpoint to which the request is made.
        {
            // The data payload for the request, structured as an object.
            activeItems: activeItems ? JSON.stringify(activeItems) : JSON.stringify(itemActive.showActiveCode()),
            orderId: orderId, // The order ID from the imported variables.
            sessionPrefix: sessionPrefix, // The session prefix from the imported variables.
            menuItemASelected: menuItemASelected, // The selected menu item in category 'A'.
            category: category, // The category for which data is being requested.
            id: id, // The specific ID of the menu item if applicable.
            buildFromSession: buildFromSession, // Boolean flag for session-based building.
            layoutType: layoutType ? layoutType : '', // The layout type, if provided.
            layoutName: layoutName ? layoutName : '', // The layout name, if provided.
            savedMenuState: savedMenuState ? JSON.stringify(savedMenuState) : '', // The saved menu state, if provided.
            itemId: itemId, // The item ID used for specific updates.
            fromSelections: fromSelections, // Boolean flag indicating the source of the request.
            dimension_state:JSON.stringify(DIMENSIONS)}
    );
}
