// export const WOO_SHOP_URL = 'https://mediastrom.com';
// export const WOO_SHOP_URL = 'https://serene-shirley.65-108-96-178.plesk.page';
export const IMAGES_VERSION_CACHE_REFRESH = 1;

export const WIDTH_INPUT = "input-width";
export const LENGTH_INPUT = "input-length";

export const BACKGROUND_NAME = "Background";

export const DIMENSIONS = {
    width: 0,
    length: 0,
};

