import $ from "jquery";
import { IMAGES_VERSION_CACHE_REFRESH } from "./consts";

// Function to update the background images of div elements within the application.
export default function replaceImages(images) {
    // Show the image loader animation as the images are being replaced.
    $('.images-loader').show();

    // Iterate through each image object provided to the function.
    for (let i = 0; i < images.length; i++) {
        let image = images[i],
            // Append a cache busting query string to the image URL to ensure the latest version is used.
            imgSrc = image.imageUrl ? `${image.imageUrl}?${IMAGES_VERSION_CACHE_REFRESH}` : "";

        // Set the background image of the div corresponding to the current image's ID to the new image source.
        $(`#div-${image.imageDivId}`).css('background-image', `url("${imgSrc}")`);

        // Once all images have been processed, hide the loader animation after a brief delay.
        if (i === images.length - 1) {
            setTimeout(function () {
                $('.images-loader').hide();
            }, 200);
        }
    }
}
